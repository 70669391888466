@import "~antd/dist/antd.less";  
@primary-color : #55a5ed;
@btn-primary-bg : #55a5ed;
@info-color : #55a5ed;

.z-select{
    .ant-select-selector{
        border-radius: 8px !important;
    }
}
#root{
    width: 100%;
    height: 100%;
}

.ant-menu.ant-menu-inline-collapsed{
    width : 50px;
}

.ant-menu-item{
    margin-top : 0px !important;
    margin-bottom: 0px !important;
    height: 44px !important;
}

.ant-layout-header{
    height: 48px;
}



/*美化滚动条*/
::-webkit-scrollbar {
    width: 5px;
    height: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #e9e9e9;
    opacity: .8;
    border-radius: 6px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #e9e9e9;
    opacity: .8;
  }
  



.round-modal{
    .ant-modal-header{
        padding : 0;
    }
    .ant-modal-content{
        overflow : hidden;
        border-radius: 8px;
    }
    .ant-form-item-label{
        font-weight: 200;
        letter-spacing: 2px;
    }
} 

.ant-tree-title{
    font-weight: 200;
    font-size: 12px;
}

.room_tree{
    .ant-tree-node-selected{
        background-color: #55a5ed !important;
        
        .ant-tree-title{
            color : white;
            font-weight: 400;
        }
    }
}

.user_tree{
    .ant-tree-node-selected{
        background-color: #13c2c2 !important;
        
        .ant-tree-title{
            color : white;
            font-weight: 400;
        }
    }
}


.zh_form{
    .ant-table-cell{
        border-bottom: 0px;
        padding : 5px 8px !important;
        font-weight: 200 !important;

        .ant-tag{
            width: 50px;
            border-radius: 10px;
            text-align: center;
        }
    }
    .ant-table-pagination.ant-pagination{
        margin : 5px 16px !important;
        zoom : .95;
        font-weight: 200 !important;
    }
}

.seleted-rooms{
    display: flex;
    height: 36px;
    align-items: center;
    background-color: #55a5ed1f;
    padding : 5px 15px;
    margin: 3px 0px;
    border-radius: 8px;
    font-weight: 200;
    font-size: 12px;
    color: #868585;
}

.seleted-users{
    display: flex;
    height: 36px;
    align-items: center;
    background-color: #13c2c21f;
    padding : 5px 15px;
    margin: 3px 0px;
    border-radius: 8px;
    font-weight: 200;
    font-size: 12px;
    color: #868585;
}


.zh-nt{
    border-radius: 14px !important;
    box-shadow: 0 3px 20px 0px rgb(0 0 0 / 12%), 0 6px 16px 1px rgb(0 0 0 / 8%), 8px -3px 20px 8px rgb(0 0 0 / 5%) !important;
    background-color: #ffffffdd !important;
    .anticon.ant-notification-notice-icon-success{
        color : #55a5ed;
    }
    .ant-notification-notice-with-icon .ant-notification-notice-description{
        font-size: 12px;
        font-weight: 200;
    }
    .ant-notification-notice-with-icon .ant-notification-notice-message{
        font-size: 14px !important;
        color: #868585;
    }
}

.zh-table{
    .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
        background: transparent;
    }

    .ant-table-small .ant-table-thead > tr > th{
        // background: #55a5ed;
        // font-size: 12px;
        color: #9b9b9b;
    }
    .ant-table-cell{
        text-align: center;
    }

    // .ant-table-cell-scrollbar{
    //     background: #e9e9e9 !important;
    // }
}

.option-button {
    width: 100px;
    margin-right: 20px;
    margin-top: 10px;
    height: 30px;
    line-height: 25px;
    border-radius: 20px;
    text-align: center;
    cursor: pointer;
  }
