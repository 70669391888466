#login-panel{
    .login-tr{
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 15px 15px 0 15px;
        border-color: #5585ed transparent transparent transparent;
        position: absolute;
        left: 27px;
    }
    .ant-input-affix-wrapper > input.ant-input{
        padding-left : 10px
    }
}